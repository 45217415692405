const useFavicon = () => {
    const setFaviconHref = (href: string) => {
        // @ts-ignore
        let link: HTMLLinkElement = document.querySelector('link[rel="shortcut icon"]') || document.querySelector('link[rel="icon"]');
        link.href = href;
    };

    return {
        setFaviconHref,
    };
};

export default useFavicon;
