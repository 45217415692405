import React, {useEffect} from 'react';
import '../App.css';
import './HomePage.css';
import {Link} from "react-router-dom";
import useDocumentTitle from "../hooks/useDocumentTitle";
import Config from "../config/Config";
import useFavicon from "../hooks/useFavicon";

function HomePage() {
    const {setDocumentTitle} = useDocumentTitle();
    const {setFaviconHref} = useFavicon();

    useEffect(() => {
        setDocumentTitle(Config.SITE_TITLE);
        setFaviconHref("favicon-phone.ico");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="content">
            <div className={"title"}>
                <h1>Hallo.</h1>
                <p>Verfügbare Tonbanddienste:</p>
            </div>
            <div>
                <Link to={"zeitansage"}><img alt="Logo Zeitansage" src="/clock.svg" style={{width:32}}/></Link>
            </div>
        </div>
    );
}

export default HomePage;
