import {useCallback, useEffect, useState} from 'react';

type VoiceOptions = {
    voice?: SpeechSynthesisVoice | null;
    text?: string;
    pitch?: number;
    rate?: number;
    volume?: number;
    onEnd?: () => void;
}

const useSpeechSynthesis = () => {
    const [voices, setVoices] = useState<SpeechSynthesisVoice[]>([]);
    const [speaking, setSpeaking] = useState(false);
    const [supported, setSupported] = useState(false);

    const processVoices = (voiceOptions: SpeechSynthesisVoice[]) => {
        setVoices(voiceOptions);
    };

    const getVoices = useCallback(() => {
        // Firefox seems to have voices upfront and never calls the
        // voiceschanged event
        let voices: SpeechSynthesisVoice[]  = window.speechSynthesis.getVoices();
        if (voices.length > 0) {
            processVoices(voices);
            return;
        }

        window.speechSynthesis.onvoiceschanged = (event) => {
            // @ts-ignore
            voices = event.target?.getVoices();
            processVoices(voices);
        };
    }, []);

    const handleEnd = (endHandler?: () => void) => {
        setSpeaking(false);
        if (endHandler) {
            endHandler();
        }
    };

    useEffect(() => {
        if (typeof window !== 'undefined' && window.speechSynthesis) {
            setSupported(true);
            getVoices();
        }
    }, [getVoices]);

    const speak = (args:VoiceOptions = {}) => {
        const { voice = null, text = '', rate = 1, pitch = 1, volume = 1, onEnd } = args;
        if (!supported) return;
        setSpeaking(true);
        // Firefox won't repeat an utterance that has been
        // spoken, so we need to create a new instance each time
        const utterance = new window.SpeechSynthesisUtterance();
        utterance.text = text;
        utterance.voice = voice;
        utterance.onend = () => handleEnd(onEnd);
        utterance.rate = rate;
        utterance.pitch = pitch;
        utterance.volume = volume;
        if (voice?.lang) {
            utterance.lang = voice?.lang; // required for android
        }
        window.speechSynthesis.speak(utterance);
    };

    const cancel = () => {
        if (!supported) return;
        setSpeaking(false);
        window.speechSynthesis.cancel();
    };

    return {
        supported,
        speak,
        speaking,
        cancel,
        voices,
    };
};

export default useSpeechSynthesis;
