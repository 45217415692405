import React from 'react';
import './App.css';
import {
    BrowserRouter,
    Route, Routes,
} from "react-router-dom";
import Number1503 from "./special/Number1503";
import HomePage from "./pages/HomePage";

function App() {
  return (
    <div className="App">
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="1503" element={<Number1503 />} />
                <Route path="zeitansage" element={<Number1503 />} />
            </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
