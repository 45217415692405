const useDocumentTitle = () => {
    const setDocumentTitle = (title: string) => {
        document.title = title;
    };

    return {
        setDocumentTitle,
    };
};

export default useDocumentTitle;
